/* methodology.css */

.methodologyContainer {
    background-color: #f9f9f9; /* Set your desired background color */
    padding: 40px 0;
  }
  
  .contentContainer {
    padding: 20px;
  }
  
  .methodologyTitle {
    color: green;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .introduction,
  .description,
  .conclusion {
    color: #333333; /* Set your desired text color */
    text-align: justify;
    margin-bottom: 20px;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .contentContainer {
      padding: 10px;
    }
  }
  