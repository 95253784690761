/* InfiniteScrollAnimation.css */
.scroll-container {
    width: 100%;
    overflow: hidden;
    background-color: rgb(33, 148, 33);
  }
  .item{
    width: 100%;
    background-color: orange;
  }
  
  .content {
    display: flex;
    animation: scrollAnimation 15s linear infinite;
    white-space: nowrap;
    color: white;
  }
  
  @keyframes scrollAnimation {
    0% {
      transform: translateX(100%);
  
    }
    50%{
      transform: translateX(0%);
    }
    100%{
      transform: translateX(-100%);
    }
  }
  