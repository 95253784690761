.fee-div img {
    background-image: url("../../../assests/images/feeStr.png");
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .custom_card{
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
  }

  .feeProcess img {
    width: 100%;      /* Image will take the full width of the parent div */
    height: auto;     /* Height will adjust automatically to maintain the aspect ratio */
    max-width: 500px;
  }