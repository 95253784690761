.management-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}

.management-container .m-part-1, .m-part-2{ 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.407);
    padding: 20px;
    border-radius: 10px;
}

.m-part-1 p{
    border: 2px solid #d8812a;
    padding: 20px;
    border-radius: 10px;
    text-align: justify;
}

.m-part-1 img{
    border-radius: 50%;
    border: 2px solid #d8812a;
}

.m-part-2 img{
    border: 2px solid #d8812a;
    border-radius: 50%;
}

.m-part-2 p{
    border: 2px solid #d8812a;
    padding: 20px;
    border-radius: 10px;
    text-align: justify;
}

.m-part-1 h5{
    text-align: center;
}
.m-part-2 h5{
    text-align: center;
}