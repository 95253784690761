/* levels.css */

.levelsContainer {
    background-color: #f9f9f9; /* Set your desired background color */
    padding: 40px 0;
    background-image: url("../../assests/images/level.jpg");
    background-position: center;
    background-size: cover;
  }
  
  .contentContainer {
    padding: 20px;
  }
  
  .introduction {
    margin-bottom: 20px;
  }
  
  .levelCard {
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #ffffff; /* Set your desired card background color */
  }
  
  .levelTitle {
    color: green;
    font-weight: 600;
  }
  
  .ageRange {
    color: #333333; /* Set your desired text color */
    font-size: 14px;
  }
  
  .description {
    color: #333333; /* Set your desired text color */
  }
  
  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .contentContainer {
      padding: 10px;
    }
  }
  