.submitAdmission{
color:#00a859;
background-color:white;
border:2px solid #00a859;
padding:2px;
border-radius:4px
}

.submit{
    color:white;
    background-color:#00a859;
    border:2px solid #00a859;
    padding:2px;
    border-radius:4px
    }
    .submit:hover{
        color:#00a859;
        background-color:white;
        border:2px solid #00a859;
        padding:2px;
        border-radius:4px
        }
        .submit:focus{
            color:#00a859;
            background-color:white;
            border:2px solid #00a859;
            padding:2px;
            border-radius:4px
            }
            .submit:active{
                color:#00a859;
                background-color:white;
                border:2px solid #00a859;
                padding:2px;
                border-radius:4px
                }
                .submitAdmission:hover{
                    color:white;
                    background-color:#00a859;
                    border:2px solid #00a859;
                    padding:2px;
                    border-radius:4px
                    }
                    .submitAdmission:focus{
                        color:white;
                        background-color:#00a859;
                        border:2px solid #00a859;
                        padding:2px;
                        border-radius:4px
                        }
                        .submitAdmission:active{
                            color:white;
                            background-color:#00a859;
                            border:2px solid #00a859;
                            padding:2px;
                            border-radius:4px
}