.footer {
 background-color:#3e4095;
 color: white;
 background-image: url("../../assests/images//bg-foo.png");
}

.follows span a {
  text-decoration: none;
}

.fw-bold , .footerDis p{
  color:white;
}
.footerDis p{
  margin: 0px;
}

.follows span {
  margin-right: 15px;
}

.link__list .link__item {
  background: transparent;
}

.link__list a {
  text-decoration: none;
  color: white;
  font-weight: 500;
}
 .widget-title {margin-top: -4px;}
.widget-title {
	color: #ffffff;
	font-size: 36px;
	font-weight: 700;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	text-transform: uppercase;
	position: relative;
	padding-bottom: 20px;
	margin-bottom: 40px;
}
.widget-title:before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 87px;
	height: 2px;
	background-color: #fff;
}
.iconButton:nth-child(1){
  background-color: #475993;
  border: 2px solid #061e69;
}
.iconButton:nth-child(1):hover{
  background-color: #061e69;
}
.iconButton:nth-child(2){
  background-color: #00cccc;
  border: 2px solid #00afef;
}
.iconButton:nth-child(2):hover{
  background-color: #00afef;
}
.iconButton:nth-child(3){
  background-color: #ed3237;
  border: 2px solid #ca363b;
}
.iconButton:nth-child(3):hover{
  background-color: rgb(255, 10, 18);
}
#instagramIcon{
  background-image: linear-gradient(-45deg, #feda75 0%, #fa7e1e 25%, #d62976 50%, #962fbf 75%, #4f5bd5 100%);
}


.iconButton {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor:pointer;
}

.footerNavLink {
  display: flex;
  gap: 5px;
}

