/* newsletter.css */


.newsletter {
  margin: 40px 0;
  background-image: url("../../assests/images/contact.jpeg");
}

.card {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.formContainer {
  display: flex;
  justify-content: center;
}

.submitButton {
  margin-top: 16px;
}

.dialogTitle {
  background-color: black;
  color: white;
}

.dialogContent {
  background-color: #f9f9f9;
}

.dialogMessage {
  color: green;
  font-weight: bold;
}
