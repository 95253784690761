.images{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100vh;

}

.headingUnderline::after{
  content: '';
  display: block;
  height: 4px;
  width: 100%;
  background-color: orange;
  transform: scaleX(0.3);
  transform-origin: left;
  transition: transform 0.3s ease;
}


.widget-line {
	color: #000000;
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.widget-line:before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 50%;
	height: 2px;
	background-color: #000000;
}

.headingUnderline:hover::after{
  transform: scaleX(1);
}

li.react-multi-carousel-item {
  height: 100%;
}

@media screen and (max-width: 991px) {
    .images{
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      width: 100%;
      background-color: #f5f5f5;
      border-radius: 10px;
    }
} 