:root {
  --primary-bg-color: #f10000;
  --secondary-bg-color: rgba(255, 255, 255, 0.966);
  --transition-duration: 0.5s;
}

.logoHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15vh;
  gap: 1rem;
  transition: ease var(--transition-duration);
}

.logoHeader img {
  height: 100px;
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.headerFbNav a,
.headerInNav a {
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
}

.headerFbNav a {
  background-color: blue;
}

.headerInNav a {
  background-image: linear-gradient(-45deg, #feda75 0%, #fa7e1e 25%, #d62976 50%, #962fbf 75%, #4f5bd5 100%);
}

.Header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  background-color: var(--secondary-bg-color);
  transition: ease var(--transition-duration);
}

.buttonStyle,
.dropbtn {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  color: white;
  text-decoration: none;
}

.headerTop,
.headerTop1 {
  position: sticky;
  z-index: 9999;
  transition: ease var(--transition-duration);
}

.headerTop {
  top: 0;
}

.headerTop1 {
  bottom: 0;
}

.schoolName {
  font-size: 2rem;
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.closeBtn,
.closeBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.5s ease-in-out;
  color: white;
}

.closeBtn {
  height: 10vh;
}

.closeBottom {
  height: 80vh;
}

@media screen and (max-width: 991px) {
  .schoolName {
    font-size: 100%;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0);
}

.dropbtn:hover {
  background-color: #ddd;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--primary-bg-color);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content .buttonStyle {
  padding: 12px 16px;
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.menu {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  background-color: #fff;
  transition: left 0.3s ease-in-out;
}

.menu.open {
  left: 0;
}


/* Add this CSS to your existing styles or create a separate CSS file */
/* CSS for animated dropdown */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
  animation: slideIn 0.3s;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

