.mission-heading-container{
    border-radius: 0px;
    padding: 10px;
    margin: 50px auto;
    max-width: 980px;
    box-shadow: 1px 2px 5px #999999;
}
.cont {
    border-radius: 0px;
    padding: 50px;
    margin: 50px auto;
    max-width: 980px;
    box-shadow: 1px 2px 5px #999999;
    background-image: url("../../assests/images/hero-img1.jpg");
}
.curve-heading {
    display: inline-block;
    padding: 20px 70px 15px;
    background: #ff9100;
    color: #fff;
    position: relative;
    margin:0 0 0 -50px;
    vertical-align: top;
    font-size:24px;
}
.curve-heading::before {
    background: #ff9100;
    bottom: -11px;
    content: "";
    height: 23px;
    left: 0;
    position: absolute;
    width: 100%;
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
}
.curve-heading::after {
    background: #ff9100;
    content: "";
    height: 100%;
    left: -6px;
    position: absolute;
    top: 0;
    width: 15px;
    -webkit-transform: rotate(172deg);
    -moz-transform: rotate(172deg);
    -o-transform: rotate(172deg);
}

.mission-content{
	padding:20px;
	background:#ffffff;
	width: 100%;
	display:inline-block;
	box-shadow:0 3px 16px 0 rgba(0, 0, 0, .11);
}
.mission-content p{
	font-size:16px;
	color:#999999;
}

.mission-content ul li span{
	padding-left: 40px;
    display:flex;
}