@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Andada+Pro:wght@400;500;600;700;800&display=swap);
.images{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100vh;

}

.headingUnderline::after{
  content: '';
  display: block;
  height: 4px;
  width: 100%;
  background-color: orange;
  -webkit-transform: scaleX(0.3);
          transform: scaleX(0.3);
  -webkit-transform-origin: left;
          transform-origin: left;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}


.widget-line {
	color: #000000;
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.widget-line:before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 50%;
	height: 2px;
	background-color: #000000;
}

.headingUnderline:hover::after{
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

li.react-multi-carousel-item {
  height: 100%;
}

@media screen and (max-width: 991px) {
    .images{
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      width: 100%;
      background-color: #f5f5f5;
      border-radius: 10px;
    }
} 

.about-heading-container{
  border-radius: 0px;
  padding: 10px;
  margin: 50px auto;
  max-width: 980px;
  box-shadow: 1px 2px 5px #999999;
}

  .about_cont {
    border-radius: 0px;
    padding: 50px;
    margin: 50px auto;
    max-width: 980px;
    box-shadow: 1px 2px 5px #999999;
    background-image: url(/static/media/aboutBack.6dd11b56.jpg);
}


.counter__title {
  font-size: 1rem;
  color: #0a2b1e;
  font-weight: 500;
}

.about__content {
  padding-left: 50px;
}

.about__img img {
  border-radius: 15px;
}

.about__content h2 {
  font-weight: 600;
}

.headingContainer{
    background-image: url(/static/media/aboutBack.6dd11b56.jpg);
    margin-bottom: 5vh;
}
/* newsletter.css */


.newsletter {
  margin: 40px 0;
  background-image: url(/static/media/contact.064ac5f9.jpeg);
}

.card {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.formContainer {
  display: flex;
  justify-content: center;
}

.submitButton {
  margin-top: 16px;
}

.dialogTitle {
  background-color: black;
  color: white;
}

.dialogContent {
  background-color: #f9f9f9;
}

.dialogMessage {
  color: green;
  font-weight: bold;
}

.mission-heading-container{
    border-radius: 0px;
    padding: 10px;
    margin: 50px auto;
    max-width: 980px;
    box-shadow: 1px 2px 5px #999999;
}
.cont {
    border-radius: 0px;
    padding: 50px;
    margin: 50px auto;
    max-width: 980px;
    box-shadow: 1px 2px 5px #999999;
    background-image: url(/static/media/hero-img1.1d3a326b.jpg);
}
.curve-heading {
    display: inline-block;
    padding: 20px 70px 15px;
    background: #ff9100;
    color: #fff;
    position: relative;
    margin:0 0 0 -50px;
    vertical-align: top;
    font-size:24px;
}
.curve-heading::before {
    background: #ff9100;
    bottom: -11px;
    content: "";
    height: 23px;
    left: 0;
    position: absolute;
    width: 100%;
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
}
.curve-heading::after {
    background: #ff9100;
    content: "";
    height: 100%;
    left: -6px;
    position: absolute;
    top: 0;
    width: 15px;
    -webkit-transform: rotate(172deg);
    -moz-transform: rotate(172deg);
    -o-transform: rotate(172deg);
}

.mission-content{
	padding:20px;
	background:#ffffff;
	width: 100%;
	display:inline-block;
	box-shadow:0 3px 16px 0 rgba(0, 0, 0, .11);
}
.mission-content p{
	font-size:16px;
	color:#999999;
}

.mission-content ul li span{
	padding-left: 40px;
    display:flex;
}
/* levels.css */

.levelsContainer {
    background-color: #f9f9f9; /* Set your desired background color */
    padding: 40px 0;
    background-image: url(/static/media/level.d10b8579.jpg);
    background-position: center;
    background-size: cover;
  }
  
  .contentContainer {
    padding: 20px;
  }
  
  .introduction {
    margin-bottom: 20px;
  }
  
  .levelCard {
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #ffffff; /* Set your desired card background color */
  }
  
  .levelTitle {
    color: green;
    font-weight: 600;
  }
  
  .ageRange {
    color: #333333; /* Set your desired text color */
    font-size: 14px;
  }
  
  .description {
    color: #333333; /* Set your desired text color */
  }
  
  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .contentContainer {
      padding: 10px;
    }
  }
  
/* methodology.css */

.methodologyContainer {
    background-color: #f9f9f9; /* Set your desired background color */
    padding: 40px 0;
  }
  
  .contentContainer {
    padding: 20px;
  }
  
  .methodologyTitle {
    color: green;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .introduction,
  .description,
  .conclusion {
    color: #333333; /* Set your desired text color */
    text-align: justify;
    margin-bottom: 20px;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .contentContainer {
      padding: 10px;
    }
  }
  
.management-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}

.management-container .m-part-1, .m-part-2{ 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.407);
    padding: 20px;
    border-radius: 10px;
}

.m-part-1 p{
    border: 2px solid #d8812a;
    padding: 20px;
    border-radius: 10px;
    text-align: justify;
}

.m-part-1 img{
    border-radius: 50%;
    border: 2px solid #d8812a;
}

.m-part-2 img{
    border: 2px solid #d8812a;
    border-radius: 50%;
}

.m-part-2 p{
    border: 2px solid #d8812a;
    padding: 20px;
    border-radius: 10px;
    text-align: justify;
}

.m-part-1 h5{
    text-align: center;
}
.m-part-2 h5{
    text-align: center;
}
.under{
    display: flex;
    align-items: center;
    justify-content: center;
}
.submitAdmission{
color:#00a859;
background-color:white;
border:2px solid #00a859;
padding:2px;
border-radius:4px
}

.submit{
    color:white;
    background-color:#00a859;
    border:2px solid #00a859;
    padding:2px;
    border-radius:4px
    }
    .submit:hover{
        color:#00a859;
        background-color:white;
        border:2px solid #00a859;
        padding:2px;
        border-radius:4px
        }
        .submit:focus{
            color:#00a859;
            background-color:white;
            border:2px solid #00a859;
            padding:2px;
            border-radius:4px
            }
            .submit:active{
                color:#00a859;
                background-color:white;
                border:2px solid #00a859;
                padding:2px;
                border-radius:4px
                }
                .submitAdmission:hover{
                    color:white;
                    background-color:#00a859;
                    border:2px solid #00a859;
                    padding:2px;
                    border-radius:4px
                    }
                    .submitAdmission:focus{
                        color:white;
                        background-color:#00a859;
                        border:2px solid #00a859;
                        padding:2px;
                        border-radius:4px
                        }
                        .submitAdmission:active{
                            color:white;
                            background-color:#00a859;
                            border:2px solid #00a859;
                            padding:2px;
                            border-radius:4px
}
.fee-div img {
    background-image: url(/static/media/feeStr.4ce9841e.png);
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .custom_card{
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
  }

  .feeProcess img {
    width: 100%;      /* Image will take the full width of the parent div */
    height: auto;     /* Height will adjust automatically to maintain the aspect ratio */
    max-width: 500px;
  }
:root {
  --primary-bg-color: #f10000;
  --secondary-bg-color: rgba(255, 255, 255, 0.966);
  --transition-duration: 0.5s;
}

.logoHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15vh;
  grid-gap: 1rem;
  gap: 1rem;
  transition: ease 0.5s;
  transition: ease var(--transition-duration);
}

.logoHeader img {
  height: 100px;
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.headerFbNav a,
.headerInNav a {
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
}

.headerFbNav a {
  background-color: blue;
}

.headerInNav a {
  background-image: linear-gradient(-45deg, #feda75 0%, #fa7e1e 25%, #d62976 50%, #962fbf 75%, #4f5bd5 100%);
}

.Header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.966);
  background-color: var(--secondary-bg-color);
  transition: ease 0.5s;
  transition: ease var(--transition-duration);
}

.buttonStyle,
.dropbtn {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  color: white;
  text-decoration: none;
}

.headerTop,
.headerTop1 {
  position: -webkit-sticky;
  position: sticky;
  z-index: 9999;
  transition: ease 0.5s;
  transition: ease var(--transition-duration);
}

.headerTop {
  top: 0;
}

.headerTop1 {
  bottom: 0;
}

.schoolName {
  font-size: 2rem;
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.closeBtn,
.closeBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.5s ease-in-out;
  color: white;
}

.closeBtn {
  height: 10vh;
}

.closeBottom {
  height: 80vh;
}

@media screen and (max-width: 991px) {
  .schoolName {
    font-size: 100%;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0);
}

.dropbtn:hover {
  background-color: #ddd;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f10000;
  background-color: var(--primary-bg-color);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content .buttonStyle {
  padding: 12px 16px;
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.menu {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  background-color: #fff;
  transition: left 0.3s ease-in-out;
}

.menu.open {
  left: 0;
}


/* Add this CSS to your existing styles or create a separate CSS file */
/* CSS for animated dropdown */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
  -webkit-animation: slideIn 0.3s;
          animation: slideIn 0.3s;
}

@-webkit-keyframes slideIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}


/* InfiniteScrollAnimation.css */
.scroll-container {
    width: 100%;
    overflow: hidden;
    background-color: rgb(33, 148, 33);
  }
  .item{
    width: 100%;
    background-color: orange;
  }
  
  .content {
    display: flex;
    -webkit-animation: scrollAnimation 15s linear infinite;
            animation: scrollAnimation 15s linear infinite;
    white-space: nowrap;
    color: white;
  }
  
  @-webkit-keyframes scrollAnimation {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
  
    }
    50%{
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
    }
    100%{
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
  }
  
  @keyframes scrollAnimation {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
  
    }
    50%{
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
    }
    100%{
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
  }
  
.footer {
 background-color:#3e4095;
 color: white;
 background-image: url(/static/media/bg-foo.fda0c629.png);
}

.follows span a {
  text-decoration: none;
}

.fw-bold , .footerDis p{
  color:white;
}
.footerDis p{
  margin: 0px;
}

.follows span {
  margin-right: 15px;
}

.link__list .link__item {
  background: transparent;
}

.link__list a {
  text-decoration: none;
  color: white;
  font-weight: 500;
}
 .widget-title {margin-top: -4px;}
.widget-title {
	color: #ffffff;
	font-size: 36px;
	font-weight: 700;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	text-transform: uppercase;
	position: relative;
	padding-bottom: 20px;
	margin-bottom: 40px;
}
.widget-title:before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 87px;
	height: 2px;
	background-color: #fff;
}
.iconButton:nth-child(1){
  background-color: #475993;
  border: 2px solid #061e69;
}
.iconButton:nth-child(1):hover{
  background-color: #061e69;
}
.iconButton:nth-child(2){
  background-color: #00cccc;
  border: 2px solid #00afef;
}
.iconButton:nth-child(2):hover{
  background-color: #00afef;
}
.iconButton:nth-child(3){
  background-color: #ed3237;
  border: 2px solid #ca363b;
}
.iconButton:nth-child(3):hover{
  background-color: rgb(255, 10, 18);
}
#instagramIcon{
  background-image: linear-gradient(-45deg, #feda75 0%, #fa7e1e 25%, #d62976 50%, #962fbf 75%, #4f5bd5 100%);
}


.iconButton {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor:pointer;
}

.footerNavLink {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}


/* =========== base style ========= */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

section,
footer {
  padding-top: 70px;
  padding-bottom: 70px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 2rem;
  line-height: 50px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #0a2b1e;
  font-weight: 600;
}

.banner-title-wrap{
	width: 100%;
    background-color: #3e4095;
    z-index: 9;
    height: 100%;
    display: block;
}
.banner-title-wrap p{
	color:#ffffff;
	text-align:left;
	font-size:18px;
}
.banner-title {
  font-size: 45px;
  text-align: left;
  position: relative;
  color: #ffffff;
  text-transform: uppercase;
  margin: 125px 0 0;
  font-weight: bold;    
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-shadow:0px 1px 0 #999;
}

p {
  color: #596b65;
  font-size: 1rem;
  line-height: 35px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
}

button.btn {
  background: #17bf9e;
  color: #fff;
  padding: 7px 25px;
  border-radius: 50px;
}

i {
  color: #17bf9e;
}

