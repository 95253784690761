
.about-heading-container{
  border-radius: 0px;
  padding: 10px;
  margin: 50px auto;
  max-width: 980px;
  box-shadow: 1px 2px 5px #999999;
}

  .about_cont {
    border-radius: 0px;
    padding: 50px;
    margin: 50px auto;
    max-width: 980px;
    box-shadow: 1px 2px 5px #999999;
    background-image: url("../../assests/images/aboutBack.jpg");
}


.counter__title {
  font-size: 1rem;
  color: #0a2b1e;
  font-weight: 500;
}

.about__content {
  padding-left: 50px;
}

.about__img img {
  border-radius: 15px;
}

.about__content h2 {
  font-weight: 600;
}
